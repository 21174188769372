import React, { Component } from "react";

import "./App.css";
// import { Form, Radio } from "semantic-ui-react";
import Survival from "./Survival";
import Empowered from "./Empowered";
import Consortium from "./Consortium";
import MetaTags from "react-meta-tags";

/*

window.location.search
window.location.href 
*/
class App extends Component {
	state = {};
	handleChange = (e, { value }) => this.setState({ generator: value });
	componentWillMount() {
		document.body.style.background = "#f1f1f1";
		let params = new URL(document.location).searchParams;
		let generator = params.get("generator");
		this.setState({ generator });
	}

	componentDidMount() {
		//console.log(window.location.search);
		let params = new URL(document.location).searchParams;
		//"?generator=consortium&test=gest"
		let generator = params.get("generator"); // is the string "Jonathan"
		//let age = parseInt(params.get("age")); // is the number 18
		//console.log(generator);
		if (generator === null) {
			//window.history.replaceState({}, document.title, "/what-are-you-app/");
		}
	}
	render() {
		return (
			<React.Fragment>
				<MetaTags>
					<title>"What are you?"</title>
					<meta
						name='description'
						content='What are you? Shades of veng generator app'
					/>
					<meta property='og:title' content='What are you? shades of v app' />
				</MetaTags>

				<br />
				{this.state.generator === "survival" && <Survival />}
				{this.state.generator === "empowered" && <Empowered />}
				{this.state.generator === "consortium" && <Consortium />}
				{!this.state.generator && (
					<div className='banner'>
						<div className='cons'>
							<a
								href='/?generator=consortium'
							>
								<h2 className='b'>
									<div className='insider' />
									Are you Eulutian or Ximian?
								</h2>
							</a>
						</div>
						<div className='emp'>
							<a
								href='/?generator=empowered'
							>
								<h2 className='b'>
									<div className='insider' />
									Which Empowered are you?
								</h2>
							</a>
						</div>
						<div className='surv'>
							<a
								href='/?generator=survival'
							>
								<h2 className='b'>
									<div className='insider' />
									What happened to you on Gaia?
								</h2>
							</a>
						</div>
					</div>
				)}
				<br />
			</React.Fragment>
		);
	}
}

export default App;
