import React, { Component } from "react";

import "semantic-ui-css/semantic.min.css";
import { Input, Image, Icon, Divider, Transition } from "semantic-ui-react";

import { options_list, twitterImages } from "./helpers.js";
import { ShareButtonIconOnly, ShareBlockStandard } from "react-custom-share";
import { FaTwitter, FaFacebook } from "react-icons/fa";
import smoothscroll from "smoothscroll-polyfill";
smoothscroll.polyfill();
class Survival extends Component {
	state = {
		generatedList: {},
		name: "The One With No Name",
		generated: false,
		value: "",
		directImage: [],
		shareText: "",
		visible: false
	};

	myRef = React.createRef();

	scroll(ref) {
		ref.current.scrollIntoView({ behavior: "smooth" });
	}

	componentWillMount() {
		document.body.style.background = "#f1f1f1";

		let params = new URL(document.location).searchParams;

		if (params.get("url") === "true") {
			let d = params.get("picked");
			d = d.split(",");
			let final_list = [];
			d.map(i => final_list.push(options_list[i]));
			this.setState({
				name: params.get("name"),
				picked: d,
				// generatedName: params.get("generatedName"),
				imgPath: params.get("imgPath"),
				generatedList: final_list,
				// cast: params.get("cast"),
				url: true,
				generated: true,
				comic: true
			});

			this.setState({
				generatedList: final_list
			});
			//	console.log("inside component i am TRUE");
			//window.history.pushState({}, document.title, "/what-are-you-app/");
		}
	}
	componentDidMount() {
		window.fbAsyncInit = function() {
			window.FB.init({
				appId: 1731948246938536,
				cookie: true,

				xfbml: true,
				version: "v2.1"
			});
		}.bind(this);
		(function(d, s, id) {
			var js,
				fjs = d.getElementsByTagName(s)[0];
			if (d.getElementById(id)) return;
			js = d.createElement(s);
			js.id = id;
			js.src = "//connect.facebook.net/en_US/sdk.js";
			fjs.parentNode.insertBefore(js, fjs);
		})(document, "script", "facebook-jssdk");
	}

	generate = () => {
		/* --------------------------GENERATOR SETUP--------------------------*/
		if (this.state.generated === true) {
			this.imgAll = [];
			this.twitText = [];
		}
		const picked = [];
		const rand = (min, max) => {
			return Math.floor(Math.random() * (max - min + 1)) + min;
		};

		const stage1 = (list, weight) => {
			var rand = function(min, max) {
				return Math.floor(Math.random() * (max - min + 1)) + min;
			};

			const generateWeighedList = (list, weight) => {
				const weighed_list = [];

				// Loop over weights

				for (var i = 0; i < weight.length; i++) {
					var multiples = weight[i] * 100;

					// Loop over the list of items
					for (var j = 0; j < multiples; j++) {
						weighed_list.push(list[i]);
					}
				}

				return weighed_list;
			};

			var weighed_list = generateWeighedList(list, weight);

			let random_num = rand(0, weighed_list.length - 1);

			//console.log(weighed_list[random_num]);
			picked.push(weighed_list[random_num]);
		};

		const stage1_list = ["died", "survived1", "survived2", "gaiaBorn"];

		const weight = [0.1, 0.18, 0.19, 0.7];

		const stage2_list = [
			"blacksmith",
			"potestates",
			"defenders",
			"raiders",
			"wiseOne",
			"whiteCross"
		];
		const gaia_born = ["swarmAbcediary", "swarmArtificer"];
		const stage3_list = [
			"luxuryCityRetirement",
			"sisterhoodOfPyrus",
			"disappeared",
			"swarmDrone",
			"outlands",
			"infected"
		];
		const survived_list = [
			"vaultHunter",
			"base9",
			"establishmentCity",
			"testSubject",
			"highway101",
			"runner",
			"heroicDeath",
			"killedByraiders",
			"diedInTrapDoor"
		];
		const swarm_option = ["swarmEscape"];
		/* --------------------------GENERATOR SETUP--------------------------*/
		/* --------------------------FIRST STAGE--------------------------*/

		stage1(stage1_list, weight);
		if (picked[0] === "died") {
			//console.log("Tough luck, man! You dead!", picked);
		} else {
			/* --------------------------SECOND STAGE--------------------------*/

			if (picked[0] === "gaiaBorn") {
				let stage2 = [...stage2_list, ...gaia_born];
				let stage2_pick = rand(0, stage2.length - 1);
				//console.log("Gaia born");
				picked.push(stage2[stage2_pick]);
			} else {
				let stage2_pick = rand(0, stage2_list.length - 1);
				//console.log(stage2_list[stage2_pick]);
				picked.push(stage2_list[stage2_pick]);
			}

			/* --------------------------THIRD STAGE--------------------------*/

			if (picked[0] === "survived1" || picked[0] === "survived2") {
				let stage3_pick = rand(0, survived_list.length - 1);
				picked.push(survived_list[stage3_pick]);
				//console.log("Survived options");
			} else if (
				picked[1] === "swarmAbcediary" ||
				picked[1] === "swarmArtificer"
			) {
				picked.push(swarm_option[0]);
				//console.log("Swarm option");
			} else {
				let stage3_pick = rand(0, stage3_list.length - 1);
				picked.push(stage3_list[stage3_pick]);
				//console.log("else");
			}
			/* --------------------------END--------------------------*/
			//console.log(picked);
		}
		const ff = () => {
			var final_list = [];
			picked.map(i => final_list.push(options_list[i]));
			//console.log(picked);
			this.setState({
				name: this.state.value,
				generatedList: final_list,
				picked: picked,
				generated: true,
				comic: true,
			});
		};
		ff();
	};
	generated = () => {
		this.setState({
			comic: false
		});
		this.scroll(this.myRef);
		setTimeout(this.generate, 1010);
	};

	changeValue = value => {
		this.setState({ value });
	};
	// let final_list = [];
	// // 	this.state.picked.map(i => final_list.push(options_list[i]));
	imgAll = [];
	twitText = [];
	textx = () => {
		let text = "";
		text =
			"@Shades_of_Venge My #EraSurvival Story: \n" +
			(this.state.value === "" ? "They call me " : "My name is ") +
			(this.state.value === "" ? this.state.name : this.state.value) +
			"\n" +
			this.state.picked
				.map(i => {
					return twitterImages[options_list[i].img] + "\n";
				})
				.join("") +
			"\nDiscover your story:\n" + `https://whatareyou.shadesofvengeance.com/?generator=survival&name=${encodeURIComponent(
				this.state.name
			)}&picked=${encodeURIComponent(this.state.picked)}&comic=true&url=true` +
			"\n\nEnter the world with the RPG:\n";

		return text;
	};
	facebook = () => {
		//window.history.pushState({}, document.title, "/what-are-you-app/");
		window.FB.ui({
			method: "share_open_graph",
			action_type: "og.shares",
			action_properties: JSON.stringify({
				object: {
					"og:url": `https://whatareyou.shadesofvengeance.com/?generator=survival&name=${encodeURIComponent(
						this.state.name
					)}&picked=${encodeURIComponent(this.state.picked)}&comic=true&url=true`
				}
			})
		});
	};

	//shouldComponentUpdate = nextProps => this.props == nextProps;
	render() {
		const twitterShareProps = {
			url: 'https://www.kickstarter.com/projects/shadesofvengeance/era-survival-expansions-2?ref=cakddx',
			button: ShareButtonIconOnly,
			buttons: [{ network: "Twitter", icon: FaTwitter }],
			text: this.state.generated && this.state.comic ? this.textx() : "derp"
		};

		const postgen = () => {
			return (
				<React.Fragment>
					<Divider horizontal>Or</Divider>
					<div className='social-gen'>
						<p>Share your story</p>
						<div className='social_div'>
							<ShareBlockStandard {...twitterShareProps} />
							<div className='social-facebook-wrapper'>
								<div className='social-facebook'>
									<button className='facebook-btn' onClick={this.facebook}>
										<FaFacebook />
									</button>
								</div>
							</div>
						</div>
					</div>
					<div className='copyright-gen'>
						<div className='coder-gen'>Kibraken</div>
						{/* <div className='site-gen'>
							<Icon name='copyright outline' />
							<a href='https://www.shadesofvengeance.com/'>
								Shades of Vengeance
							</a>
						</div> */}
					</div>
				</React.Fragment>
			);
		};
		return (
			<React.Fragment>
				<div className='App'>
					<h2 className='b'>What happened to you on Gaia? </h2>
					<br ref={this.myRef} />

					<div className='comic'>
						{this.state.generated &&
							this.state.generatedList.map(i => {
								let patho = "img/" + i.img + ".jpg";

								return (
									<Transition
										transitionOnMount={true}
										animation='scale'
										duration={1000}
										unmountOnHide={true}
										visible={this.state.comic}
									>
										<div key={i.text + Math.random()} className='panel'>
											<Image
												as='div'
												centered
												verticalAlign='middle'
												wrapped
												size='big'
												src={patho}
											/>

											<p className='text top-left'>
												<strong>
													{this.state.value === ""
														? this.state.name
														: this.state.value}
												</strong>
												{i.text.replace("[Name]", " ")}
											</p>
										</div>
									</Transition>
								);
							})}
					</div>
					<Input
						action={{
							color: "violet",
							content: "OK",
							onClick: this.state.generated ? this.generated : this.generate
						}}
						actionPosition='right'
						placeholder={
							this.state.generated
								? "Generate New Story"
								: "Enter your full name to find out!"
						}
						className='inp'
						value={this.state.value}
						onChange={e => this.changeValue(e.target.value)}
					/>

					{/* POSTGEN */}
					{this.state.generated && postgen()}
					{/*this.state.generated && !this.state.url && postgen()*/}
				</div>
			</React.Fragment>
		);
	}
}

export default Survival;
