export const options_list = {
	died: {
		text: "[Name] died in the Cataclysm.",
		img: "Area of Effect Damage"
	},
	survived1: {
		text: "[Name] survived the Cataclysm in a bunker.",
		img: "Vault"
	},
	survived2: {
		text: "[Name] survived the Cataclysm on the surface.",
		img: "Survival"
	},
	gaiaBorn: {
		text: "[Name] was born into post-Cataclysm Gaia.",
		img: "Gaia"
	},

	blacksmith: {
		text: "[Name] joined a small settlement as a Blacksmith.",
		img: "Blacksmith"
	},
	potestates: {
		text: "[Name] joined Potestates, looking to harness the Infection.",
		img: "Restrained"
	},
	defenders: {
		text: "[Name] joined the Defenders, fighting to keep the Infected at bay.",
		img: "Underestimation"
	},
	raiders: {
		text:
			"[Name] joined a band of Raiders who preyed on whoever crossed their path.",
		img: "Thrown Combat"
	},
	wiseOne: {
		text:
			"[Name] trained to be a Wise One, understanding the pre-Cataclysm technology.",
		img: "Wise One"
	},
	whiteCross: {
		text: "[Name] joined the White Cross, hoping to save lives.",
		img: "White Cross Dispatch Centre"
	},
	aquaCitizen: {
		text: "[Name] became a Citizen of Aqua.",
		img: "Life on Aqua"
	},

	swarmAbcediary: {
		text:
			"[Name] joined the Swarm as an Abcediary, converting others to their will.",
		img: "Abcediary",
		requisites: "Only possible if born into post-Cataclysm Gaia"
	},
	swarmArtificer: {
		text:
			"[Name] joined the Swarm as an Artificer, creating amazing technology.",
		img: "Artificers",
		requisites: "Only possible if born into post-Cataclysm Gaia"
	},

	luxuryCityRetirement: {
		text:
			"[Name] made enough money to retire to City and live in luxury for the remainder of their life!",
		img: "A Just Reward"
	},
	sisterhoodOfPyrus: {
		text:
			"[Name] joined the Sisterhood of Pyrus and lived the rest of their days in safety.",
		img: "Sisterhood of Pyrus"
	},
	disappeared: {
		text:
			"[Name] was forced to leave to flee the Swarm. They disappeared into unknown parts of Gaia.",
		img: "disappeared"
	},
	swarmDrone: {
		text: "[Name] was taken by the Swarm as a Drone.",
		img: "DronesFinal"
	},
	outlands: {
		text: "[Name] ventured into the Outlands, looking for new opportunities.",
		img: "outlands"
	},
	infected: {
		text:
			"[Name] became Infected and decided to explore Fallout, seeing what no Human had seen before.",
		img: "Fallout"
	},

	vaultHunter: {
		text: "[Name] eventually became a famous Vault Hunter and retired wealthy.",
		img: "vaultHunter"
	},
	base9: {
		text:
			"[Name] ventured into Base 9 to speak with the University of Planet and was never seen again…",
		img: "Base 9"
	},
	establishmentCity: {
		text:
			"[Name] ended up running an establishment on City and just getting by.",
		img: "City"
	},
	testSubject: {
		text: "[Name] was used by Potestates as a test subject.",
		img: "The Gods of Gaia"
	},
	highway101: {
		text: "[Name] was taken on as a defender of Highway 101.",
		img: "Highway 101"
	},
	runner: {
		text:
			"[Name] joined the Runner’s Guild in desperation, vanishing on their first mission.",
		img: "Runner"
	},
	heroicDeath: {
		text:
			"[Name] died in a heroic last stand, saving the rest of their settlement from Infected.",
		img: "DarkCorridorFinal"
	},
	killedByraiders: {
		text: "[Name] was killed by Raiders in an assault on their hometown.",
		img: "The Pylon"
	},
	diedInTrapDoor: {
		text: "[Name] died to Trap-Door Ants unexpectedly!",
		img: "Trap-Door Ant Colony"
	},
	swarmEscape: {
		text: "[Name] escaped the Swarm to rejoin the population of Gaia.",
		img: "HidingDroneFinal",
		requisites: "only possible if joined the Swarm in Stage 2"
	}
};

export const twitterImages = {
	"A Just Reward": "pic.twitter.com/ZDgHwaPZSX",
	Abcediary: "pic.twitter.com/RaPdjkTTL9",
	"Area of Effect Damage": "pic.twitter.com/QO9r47DTHt",
	Artificers: "pic.twitter.com/17nODUTJQf",
	"Base 9": "pic.twitter.com/NXoBrbNt9f",
	Blacksmith: "pic.twitter.com/ahR36Mnhes",
	City: "pic.twitter.com/KSCm0f8j7k",
	DarkCorridorFinal: "pic.twitter.com/fkdDFDRogH",
	disappeared: "pic.twitter.com/KKAWwMz7Ut",
	DronesFinal: "pic.twitter.com/2yLUgxiFT8",
	Fallout: "pic.twitter.com/eb7DBIierh",
	Gaia: "pic.twitter.com/CzvvETqjqq",
	HidingDroneFinal: "pic.twitter.com/bDVHFtzNwo",
	"Highway 101": "pic.twitter.com/ztZItA19Qh",
	"Life on Aqua": "pic.twitter.com/dJ8V3Dwwaf",
	outlands: "pic.twitter.com/6KK0a6E45r",
	Restrained: "pic.twitter.com/AlCHE5exdN",
	Runner: "pic.twitter.com/eVicNzeYqX",
	"Sisterhood of Pyrus": "pic.twitter.com/2QIJM8GtkF",
	Survival: "pic.twitter.com/bVHXsRRaYv",
	"The Gods of Gaia": "pic.twitter.com/eVwLlqnppe",
	"The Pylon": "pic.twitter.com/a8HCueQc3b",
	"Thrown Combat": "pic.twitter.com/kDGmlR9Z7p",
	"Trap-Door Ant Colony": "pic.twitter.com/SqDnxK5J80",
	Underestimation: "pic.twitter.com/aOJoHWF3XE",
	Vault: "pic.twitter.com/I0Zyt7Szor",
	vaultHunter: "pic.twitter.com/HDduJs8zWW",
	"White Cross Dispatch Centre": "pic.twitter.com/v1QdIMDiFy",
	"Wise One": "pic.twitter.com/RPQwdwKql1",

	//ximian
	Euluthian: "pic.twitter.com/o32LLfHne8",
	Brain: "pic.twitter.com/Yde789UVRP",
	Politician: "pic.twitter.com/Y8RpG94onP",
	Worker: "pic.twitter.com/tpNW2bV0HY",

	//empowered
	"Electro-Girl": "pic.twitter.com/fPRZuRYc0i",
	Fauna: "pic.twitter.com/QU9IG6em4q",
	"Assassin Twin": "pic.twitter.com/L6UqFNJx9C",
	"Helen Garnet": "pic.twitter.com/7foj9e9MnP",
	Drift: "pic.twitter.com/A5mSR4O1ey",
	Penumbra: "pic.twitter.com/oXfFC5gICb",
	Harbinger: "pic.twitter.com/Ta9XHwWkAq",
	Unnamed: "pic.twitter.com/EACctJ8XFr",
	"Zoey The Animator": "pic.twitter.com/WAVJBN7u5C",
	Violete: "pic.twitter.com/JfF7K5WLf2",
	"K’Jarr of Atlantis": "pic.twitter.com/Fhnl2abu2f",
	"Martian Pants Man": "pic.twitter.com/MuoInJMaTV",
	Artemis: "pic.twitter.com/jKI07EiImi",
	Vigilance: "pic.twitter.com/j2IWHSNY9m",
	Ixchel: "pic.twitter.com/bXnhUyb1PS",
	"Blue-Shift": "pic.twitter.com/bj1wvCz0f8",
	"The Human Shield": "pic.twitter.com/zK6uWRceCZ",
	Lacuna: "pic.twitter.com/049eVRg1pZ",
	Lobo: "pic.twitter.com/SpwBspvMqm",
	"Empress of Bones": "pic.twitter.com/B5HdCVAmHC",
	Melqart: "pic.twitter.com/gVl6w9oMnA",
	Cecropia: "pic.twitter.com/Vwx6Ob3GNk",
	Bastion: "pic.twitter.com/QkfZVF13lb"
};

export const short_Hero_list = [
	"Electro-Girl",
	"Electro",
	//"Girl", // probably remove this showing too frequently in the distances
	"Fauna",
	"Assassin Twin",
	"Assassin",
	"Twin",
	"Helen Garnet",
	"Helen",
	"Garnet",
	"Drift",
	"Penumbra",
	"Harbinger",
	"Unnamed",
	"Zoey The Animator",
	"Zoey",
	"Animator",
	"Violete",
	"K’Jarr of Atlantis",
	"K’Jarr",
	"Atlantis",
	"Martian Pants Man",
	"Martian",
	"Pants",
	"Artemis",
	"Vigilance",
	"Ixchel",
	"Blue-Shift",
	"Blue",
	"Shift",
	"The Human Shield",
	"Human",
	"Shield",
	"Lacuna",
	"Lobo",
	"Empress of Bones",
	"Empress",
	"Bones",
	"Melqart",
	"Cecropia",
	"Bastion"
];
export const superHeroList = {
	"Electro-Girl": {
		img: "ElectroGirl",
		text:
			"Enthusiastic and upbeat, Electro Girl was one of the first heroes to emerge in the new era of the Empowered!"
	},
	Fauna: {
		img: "Fauna",
		text:
			"Caring primarily about his community, Fauna generally rejects fighting world-level threats, using his shape-shifting abilities to protect those around him."
	},
	"Assassin Twin": {
		img: "AssassinTwin",
		text:
			"The deadliest assassins in the Guild, the Assassin Twins have hundreds of contracts to their name, including many Empowered!"
	},
	"Helen Garnet": {
		img: "HelenGarnet",
		text:
			"With the power to wield magic, Helen enthusiastically joined the Empowered Department after being released from experimentation by an unfriendly government."
	},
	Drift: {
		img: "Drift",
		text:
			"Drift’s ability to alter her weight allows her to fly or come crashing down from above. Never questioning why she is fighting, she puts the world before her own wishes."
	},
	Penumbra: {
		img: "Penumbra",
		text:
			"Gaining the power to wield shadows led Penumbra down the path of being a master thief. However, she can’t resist helping Aegis to save the world when she’s needed!",
		storelink: {
			text: "Want to know more about Penumbra? Read her first comic here!",
			link: "https://www.shadesofvengeance.com/product/era-empowered-comic-penumbra-1-digital-version/"
		}
	},
	Harbinger: {
		img: "Harbinger",
		text:
			"An alien from another world, Harbinger is permanently phased – objects pass through him. He can, however, control individuals when he enters them. He uses this ability to boost the powers of the villains of Terra, preparing heroes and villains alike for the arrival of a great threat…"
	},
	Unnamed: {
		img: "Unnamed",
		text:
			"Haunted by her power to make anyone’s worst nightmare come to life, Unnamed tries to avoid using her Empowered Ability as much as possible for fear of hurting others. However, that’s not always possible…"
	},
	"Zoey The Animator": {
		img: "Zoey",
		text:
			"Although she died before the current era of the Empowered began, her Uncle’s sacrifice has reanimated Zoey’s body to continue the fight. With the ability to bring objects around her to life under her control, she is a formidable force for order on Terra."
	},
	Violete: {
		img: "Violete",
		text:
			"A student of mythology, Violete always wants to do the right things for the right reasons. Her super strength and ability to fly offer her the chance to fight injustice, which she embraces willingly.",
		audio: {
			text: "Want to know more? Check out her Audio story, 'Vesi':",
			link: "Vesi"
		},
		storelink: {
			text: "Want to know more about Violete? Read her first comic here!",
			link: "https://www.shadesofvengeance.com/product/violete-unstoppable-digital/",
		}
	},

	"K’Jarr of Atlantis": {
		img: "Kjarr",
		text:
			"Exiled from his people and looking for a new home, K’Jarr eventually founded Aegis with several others. His telepathic abilities and biological superiority to the average human – even though he is weak for an Atlantean – have served him well in his fight for justice."
	},
	"Martian Pants Man": {
		img: "MartianPantsMan",
		text:
			"Although he always preferred the name “Forethought”, declaring himself “Martian Pants Man, defender of the universe” on television meant that the name stuck. His power – to pull any object he has recently encountered out of his pocket – along with his light-hearted attitude and natural leadership skills have allowed him to lead Aegis through its early dark days and save more lives than people might immediately assume.",
		audio: {
			text: "Want to know more? Check out his Audio Story, “Technodile”:",
			link: "Technodile"
		}
	},

	Artemis: {
		img: "Artemis",
		text:
			"The leader of the Old Gods, Artemis is a formidably powerful telekinetic with a firm belief that she and her fellows should be ruling the world once more, as they did in eons past. She will not take kindly to those who stand in her way…",
		audio: {
			text: "Want to know more? Check out her Audio Story, “Artemis”:",
			link: "Artemis"
		}
	},

	Vigilance: {
		img: "Vigilance",
		text:
			"Although she sided with the Harbingers for most of her time as an Empowered, Vigilance always believed it was because they knew the truth of what was to come… until she was betrayed by them. Her power to see through the eyes of other Empowered aided her escape and allowed her to become a free agent."
	},
	Ixchel: {
		img: "Ixchel",
		text:
			"While she is one of the lesser-known members of Aegis, Ixchel’s ability to conjure spirit hands from beyond has made her able to save many lives over the years…"
	},
	"Blue-Shift": {
		img: "BlueShift",
		text:
			"The fastest person on the planet, Blue-Shift thinks and moves faster than most of the rest of us can imagine. However, being able to move quickly does not solve every problem, as she has found throughout being an Empowered!",
		storelink: {
			text: "Want to know more about Blue-Shift's story? She already has two comics, and you can read the first here!",
			link: "https://www.shadesofvengeance.com/product/blue-shift-digital/"
		}
	},
	"The Human Shield": {
		img: "HumanShield",
		text:
			"Adam is a scientist so, when he found his Empowered Ability involved redirection of momentum, his instant reaction was to learn all he could about it. Over the years, he became one of the leading authorities on Empowered and the genetic repercussions of the condition."
	},
	Lacuna: {
		img: "Lacuna",
		text: "Lacuna is a hot-headed young man who got into fighting crime by looking for vengeance. After another Empowered questioned his motives, he ended up working with Aegis, eventually even leading a team.",
		storelink: {
			text: "Want to know more about Lacuna and his adventures? Check out his very own comic!",
			link: "https://www.shadesofvengeance.com/product/era-empowered-comic-lacuna-1-digital-version/"
		}
	},
	Lobo: {
		img: "Lobo",
		text: "Lobo has one goal – take down the drug cartels who killed his family. He’ll stop at nothing, including facing down Empowered with vastly superior power with nothing more than martial arts training and an iron will.",
		storelink: {
			text: "Want to know more about Lobo? Check out his comic!",
			link: "https://www.shadesofvengeance.com/product/lobo-dauntless-digital/"
		}
	},
	"Empress of Bones": {
		img: "Empress",
		text:
			"The Empress of Bones has risen through the ranks of her local drug cartels with one aim: eliminate them. She is not naïve enough to believe that she can destroy them from the outside, however, so she fights against them from the inside, commanding power and respect with her Empowered ability to grow her bones in any formation."
	},
	Melqart: {
		img: "Melqart",
		text:
			"Constructed by a scientist who was looking for atonement after his first creation destroyed a town and started a war, Melqart has always attempted to be a hero. While he fell into the hands of some unsavoury individuals, he has never stopped striving for justice."
	},
	Cecropia: {
		img: "Cecropia",
		text:
			"Initially a minor member of Aegis, Sonja renamed herself Cecropia when she realised she had inherited a new power – absorbing the power levels of any Empowered she kills. Rising to the top of the Assassins Guild, she decided to solve Terra’s “Empowered problem” personally…"
	},
	Bastion: {
		img: "Bastion",
		text:
			"Big, strong and a teddy bear, Bastion is one of the friendliest Empowered in Aegis. As an ex-military man, he protects others as his duty as well as his habit."
	}
};
export const getEditDistance = function(a, b) {
	if (a.length == 0) return b.length;
	if (b.length == 0) return a.length;

	var matrix = [];

	// increment along the first column of each row
	var i;
	for (i = 0; i <= b.length; i++) {
		matrix[i] = [i];
	}

	// increment each column in the first row
	var j;
	for (j = 0; j <= a.length; j++) {
		matrix[0][j] = j;
	}

	// Fill in the rest of the matrix
	for (i = 1; i <= b.length; i++) {
		for (j = 1; j <= a.length; j++) {
			if (b.charAt(i - 1) == a.charAt(j - 1)) {
				matrix[i][j] = matrix[i - 1][j - 1];
			} else {
				matrix[i][j] = Math.min(
					matrix[i - 1][j - 1] + 1, // substitution
					Math.min(
						matrix[i][j - 1] + 1, // insertion
						matrix[i - 1][j] + 1
					)
				); // deletion
			}
		}
	}

	return matrix[b.length][a.length];
};
