import React, { Component } from "react";
import { Input, Image, Transition, Divider, Icon } from "semantic-ui-react";
import {
	short_Hero_list,
	getEditDistance,
	superHeroList,
	twitterImages
} from "./helpers.js";
import ReactAudioPlayer from "react-audio-player";
import { ShareButtonIconOnly, ShareBlockStandard } from "react-custom-share";
import { FaTwitter, FaFacebook } from "react-icons/fa";

class Empowered extends Component {
	state = {
		name: "Unnamed",
		generated: false,
		value: "",
		hero: "",
		comic: true
	};

	componentDidMount() {
		window.fbAsyncInit = function() {
			window.FB.init({
				appId: 1731948246938536,
				cookie: true,

				xfbml: true,
				version: "v2.1"
			});
		}.bind(this);
		(function(d, s, id) {
			var js,
				fjs = d.getElementsByTagName(s)[0];
			if (d.getElementById(id)) return;
			js = d.createElement(s);
			js.id = id;
			js.src = "//connect.facebook.net/en_US/sdk.js";
			fjs.parentNode.insertBefore(js, fjs);
		})(document, "script", "facebook-jssdk");
	}

	componentWillMount() {
		document.body.style.background = "#f1f1f1";

		let params = new URL(document.location).searchParams;

		if (params.get("url") === "true") {
			this.setState({
				name: params.get("name"),
				hero: params.get("hero"),
				// generatedName: params.get("generatedName"),
				imgPath: params.get("imgPath"),
				// cast: params.get("cast"),
				url: true,
				comic: true,
				generated: true

				// 	hero: final,
				// generated: true,
				// imgPath: path,
				// comic: true
			});
			//	console.log("inside component i am TRUE");
			// window.history.pushState({}, document.title, "/what-are-you-app/");
		}
	}

	generate = () => {
		let pick = [];
		let answer = this.state.value === "" ? this.state.name : this.state.value;

		for (let i in short_Hero_list) {
			let rec = short_Hero_list[i];
			//console.log(getEditDistance(rec, answer));
			let levenshtein = getEditDistance(rec, answer);

			if (pick.length > 0) {
				let check = Object.keys(pick[pick.length - 1])[0];
				if (check > levenshtein) {
					//console.log("less");
					pick.splice(0, pick.length);
					pick.push({ [levenshtein]: short_Hero_list[i] });

					//console.log("add new one");
				} else if (check == levenshtein) {
					//console.log("equal");
					pick.push({ [levenshtein]: short_Hero_list[i] });
					//console.log("add new one");
				} else if (check < levenshtein) {
					//console.log("greater");
				} else {
					//pick.push({ [levenshtein]: Hero_list[i] });
					console.log("uhh");
				}
			} else {
				pick.push({ [levenshtein]: short_Hero_list[i] });
				//console.log("add initial");
			}
			//console.log(Object.keys(pick[pick.length - 1])[0]);
			//Object.keys(pick[pick.length - 1])[0];
			//getEditDistance(rec, answer);
		}
		//	console.log(pick);
		let finalPick = pick[Math.floor(Math.random() * pick.length)];

		let final = Object.values(finalPick).toString();
		if (final === "Electro") {
			final = "Electro-Girl";
		} else if (final === "Assassin" || final === "Twin") {
			final = "Assassin Twin";
		} else if (final === "Zoey" || final === "Animator") {
			final = "Zoey The Animator";
		} else if (final === "K’Jarr" || final === "Atlantis") {
			final = "K’Jarr of Atlantis";
		} else if (
			final === "Martian" ||
			final === "Pants" ||
			answer === "Phil Adams" ||
			answer === "Phil adams" ||
			answer === "phil adams" ||
			answer === "phil Adams" ||
			answer === "PhilAdams" ||
			answer === "philadams" ||
			answer === "phil_adams" ||
			answer === "Adams" ||
			answer === "Phil_Adams" ||
			answer === "Phil-Adams"
		) {
			final = "Martian Pants Man";
		} else if (final === "Blue" || final === "Shift") {
			final = "Blue-Shift";
		} else if (final === "Human" || final === "Shield") {
			final = "The Human Shield";
		} else if (final === "Empress" || final === "Bones") {
			final = "Empress of Bones";
		} else if (final === "Helen" || final === "Garnet") {
			final = "Helen Garnet";
		}

		//console.log(final);
		let path = "img/empowered/" + superHeroList[final].img + ".jpg";
		this.setState({
			hero: final,
			generated: true,
			imgPath: path,
			comic: true
		});
	};

	changeValue = value => {
		this.setState({ value });
	};
	textx = () => {
		let text = "";
		text =
			"My Era: The Empowered Hero: \n" +
			"I am " +
			this.state.hero +
			"\n" +
			twitterImages[this.state.hero] +
			"\n @Shades_of_Venge \n" +
			"Click here to find out more about this Empowered or find out which one you are!";

		return text;
	};
	facebook = () => {
		// window.history.pushState({}, document.title, "/what-are-you-app/");
		window.FB.ui({
			method: "share_open_graph",
			action_type: "og.shares",
			action_properties: JSON.stringify({
				object: {
					"og:url": `https://whatareyou.shadesofvengeance.com/?generator=empowered&name=${encodeURIComponent(
						this.state.name
					)}&hero=${encodeURIComponent(
						this.state.hero
					)}&generated=${encodeURIComponent(
						this.state.generated
					)}&imgPath=${encodeURIComponent(this.state.imgPath)}&comic=true&url=true`
				}
			})
		});
	};
	generated = () => {
		this.setState({
			comic: false
		});

		setTimeout(this.generate, 1010);
	};

	render() {
		const twitterShareProps = {
			url: `https://whatareyou.shadesofvengeance.com/?generator=empowered&name=${encodeURIComponent(
				this.state.name
			)}&hero=${encodeURIComponent(
				this.state.hero
			)}&generated=${encodeURIComponent(
				this.state.generated
			)}&imgPath=${encodeURIComponent(this.state.imgPath)}&comic=true&url=true`,
			button: ShareButtonIconOnly,
			buttons: [{ network: "Twitter", icon: FaTwitter }],

			text: this.state.generated ? this.textx() : "derp"
		};

		const postgen = () => {
			return (
				<React.Fragment>
					<Divider horizontal>Or</Divider>

					<div className='social-gen'>
						<p>Share your hero</p>

						<div className='social_div'>
							<ShareBlockStandard {...twitterShareProps} />
							<div className='social-facebook-wrapper'>
								<div className='social-facebook'>
									<button className='facebook-btn' onClick={this.facebook}>
										<FaFacebook />
									</button>
								</div>
							</div>
						</div>
					</div>
					<div className='copyright-gen'>
						<div className='coder-gen'>Kibraken</div>
						{/* <div className='site-gen'>
							<Icon name='copyright outline' />
							<a href='https://www.shadesofvengeance.com/'>
								Shades of Vengeance
							</a>
						</div> */}
					</div>
				</React.Fragment>
			);
		};
		return (
			<React.Fragment>
				<div className='App'>
					<h2 className='b'>Which Empowered are you? </h2>
					<br ref={this.myRef} />

					<div className='comic comicEmp'>
						{this.state.generated && (
							<Transition
								transitionOnMount={true}
								animation='scale'
								duration={1000}
								unmountOnHide={true}
								visible={this.state.comic}
							>
								<div className='test-panel panel'>
									<Image
										as='div'
										centered
										verticalAlign='middle'
										wrapped
										size='big'
										src={this.state.imgPath}
										className='empImg'
									/>

									<div className='test-text'>
										<h2 className='h1head'>{this.state.hero}</h2>
										<p>{superHeroList[this.state.hero].text}</p>

										{superHeroList[this.state.hero].audio ? (
											<div>
												<p>{superHeroList[this.state.hero].audio.text}</p>
												<ReactAudioPlayer
													src={
														"audio/" +
														superHeroList[this.state.hero].audio.link +
														".mp3"
													}
													controls
												/>
											</div>
										) : (
											""
										)}

										{superHeroList[this.state.hero].storelink ? (
											<div>
												<p><a href={superHeroList[this.state.hero].storelink.link}>{superHeroList[this.state.hero].storelink.text}</a></p>
											</div>
										) : (
											""
										)}
									</div>
								</div>
							</Transition>
						)}
					</div>
					<Input
						action={{
							color: "orange",
							content: "OK",
							onClick: this.state.generated ? this.generated : this.generate
						}}
						actionPosition='right'
						placeholder={
							this.state.generated
								? "Generate Another Name"
								: "Enter your full name to find out!"
						}
						className='inp'
						value={this.state.value}
						onChange={e => this.changeValue(e.target.value)}
					/>

					{/* POSTGEN */}
					{this.state.generated && postgen()}
				</div>
			</React.Fragment>
		);
	}
}

export default Empowered;
