import React, { Component } from "react";
import { Input, Image, Transition, Divider, Icon } from "semantic-ui-react";

import { FaTwitter, FaFacebook } from "react-icons/fa";
import { ShareButtonIconOnly, ShareBlockStandard } from "react-custom-share";
import { twitterImages } from "./helpers.js";
import MetaTags from "react-meta-tags";
class Consortium extends Component {
	state = {
		name: "",
		generatedName: "",
		defaultName: "Shade",
		generated: false,
		value: "",
		comic: true,
		imgPath: "",
		cast: "",
		url: false
	};

	componentDidMount() {
		window.fbAsyncInit = function() {
			window.FB.init({
				appId: 1731948246938536,
				cookie: true,

				xfbml: true,
				version: "v2.1"
			});
		}.bind(this);
		(function(d, s, id) {
			var js,
				fjs = d.getElementsByTagName(s)[0];
			if (d.getElementById(id)) return;
			js = d.createElement(s);
			js.id = id;
			js.src = "//connect.facebook.net/en_US/sdk.js";
			fjs.parentNode.insertBefore(js, fjs);
		})(document, "script", "facebook-jssdk");
	}

	componentWillMount() {
		document.body.style.background = "#f1f1f1";

		let params = new URL(document.location).searchParams;

		if (params.get("url") === "true") {
			this.setState({
				name: params.get("name"),
				generatedName: params.get("generatedName"),
				imgPath: params.get("imgPath"),
				cast: params.get("cast"),
				url: true,
				comic: true,
				generated: true
			});
			//console.log("inside component i am TRUE");
			//window.history.pushState({}, document.title, "/what-are-you-app/");
		}
	}

	generate = () => {
		let vovels;
		let name;
		const casts = ["Brain", "Politician", "Worker"];
		if (this.state.value == "") {
			name = this.state.defaultName;
		} else {
			name = this.state.value;
		}
		function getVowels(str) {
			var m = str.match(/[aeiou]/gi);
			return m === null ? 0 : (vovels = m.length);
		}
		getVowels(name);
		if (vovels <= 1 && /^[aeiou]/gi) {
			let clean = name.replace(/[^\w\s-_]/gi, "").toLowerCase();
			clean = clean.replace(/[aeiouy]$/gi, "");
			clean = clean.replace(/^[aeiou]/g, "");
			clean = clean.charAt(0).toUpperCase() + clean.slice(1) + "'";
			let castPick = casts[Math.floor(Math.random() * casts.length)];
			let path = "img/consor/Ximian_" + castPick + ".png";
			this.setState({
				generated: true,
				generatedName: clean,
				cast: castPick,
				comic: true,
				imgPath: path,
				name: (name = name.charAt(0).toUpperCase() + name.slice(1))
			});
		} else if (vovels <= 3) {
			//remove any special symbols an numbers
			let clean = name.replace(/[^\w\s-_]/gi, "").toLowerCase();
			//check for trailing vovel and replace it
			clean = clean.replace(/[aeiou]$/gi, "");
			//replace vovels
			//clean = clean.replace(/(?<=.{1})[aeiou\s]+/g, "'");
			clean = clean.replace(/[\s-_]/g, "'");
			clean = clean.replace(/\B[aeiou]+/g, "'");
			//First letter to uppercase
			clean = clean.charAt(0).toUpperCase() + clean.slice(1);

			let castPick = casts[Math.floor(Math.random() * casts.length)];

			let path = "img/consor/Ximian_" + castPick + ".png";
			this.setState({
				generated: true,
				generatedName: clean,
				cast: castPick,
				comic: true,
				imgPath: path,
				name: (name = name.charAt(0).toUpperCase() + name.slice(1))
			});
		} else if (vovels > 3) {
			//remove any special symbols an numbers
			let clean = name.replace(/[^\w]/gi, "").toLowerCase();
			//remove any hard consonants: b, d, k, p, q, t, x
			clean = clean.replace(/[bdkpqtx]/gi, "");
			//double the vowels at random unless they are:
			// - i – in which case we add an a afterwards
			// - already two in a row after removing letters.
			clean = clean.split("");
			let newArr = [...clean];
			clean.map((i, index) => {
				if (i == "i") {
					newArr.splice(index, 1, "ie");
				} else if (/[aeiou]/gi.test(i) && i == clean[index + 1]) {
					newArr.splice(index, 1, i + i);
					newArr.splice(index + 1, 1, "");
				} else if (/[aeiou]/gi.test(i) && i !== clean[index - 1]) {
					Math.random() > 0.5
						? newArr.splice(index, 1, i + i)
						: console.log("");
				}
			});
			newArr = newArr.join("");
			newArr = newArr.charAt(0).toUpperCase() + newArr.slice(1);

			this.setState({
				generated: true,
				generatedName: newArr,
				cast: "Euluthian",
				comic: true,
				imgPath: "img/consor/Euluthian.png",
				name: (name = name.charAt(0).toUpperCase() + name.slice(1))
			});
		} else {
			console.log("uh oh");
		}
	};
	changeValue = value => {
		this.setState({ value });
	};
	textx = () => {
		let text = "";
		text =
			"What is your Era: The Consortium Name? \n" +
			"My Ximian Name is: " +
			this.state.generatedName +
			"\n" +
			`${
				this.state.cast == "Euluthian"
					? "I am a " + this.state.cast + "."
					: "I am an " + this.state.cast + " Caste Ximian."
			}` +
			"\n" +
			twitterImages[this.state.cast] +
			"\n @Shades_of_Venge \n Click here to find out your Era: The Consortium Name!";
		return text;
	};

	generated = () => {
		this.setState({
			comic: false
		});
		setTimeout(this.generate, 1010);
	};
	facebook = () => {
		// window.history.pushState({}, document.title, "/what-are-you-app/");
		window.FB.ui({
			method: "share_open_graph",
			action_type: "og.shares",
			action_properties: JSON.stringify({
				object: {
					"og:url": `https://whatareyou.shadesofvengeance.com/?generator=consortium&name=${encodeURIComponent(
						this.state.name
					)}&generatedName=${encodeURIComponent(
						this.state.generatedName
					)}&cast=${encodeURIComponent(
						this.state.cast
					)}&imgPath=${encodeURIComponent(this.state.imgPath)}&comic=true&url=true`
				}
			})
		});
	};

	render() {
		const twitterShareProps = {
			url: `https://whatareyou.shadesofvengeance.com/?generator=consortium&name=${encodeURIComponent(
				this.state.name
			)}&generatedName=${encodeURIComponent(
				this.state.generatedName
			)}&cast=${encodeURIComponent(
				this.state.cast
			)}&imgPath=${encodeURIComponent(this.state.imgPath)}&comic=true&url=true`,
			button: ShareButtonIconOnly,
			buttons: [{ network: "Twitter", icon: FaTwitter }],

			text: this.state.generated ? this.textx() : "derp"
			//longtext: `Take a look at this super website I have just found.`,
		};

		const postgen = () => {
			return (
				<React.Fragment>
					<Divider horizontal>Or</Divider>

					<div className='social-gen'>
						<p>Share your name</p>

						<div className='social_div'>
							<ShareBlockStandard {...twitterShareProps} />

							<div className='social-facebook-wrapper'>
								<div className='social-facebook'>
									<button className='facebook-btn' onClick={this.facebook}>
										<FaFacebook />
									</button>
								</div>
							</div>
						</div>
					</div>
					<div className='copyright-gen'>
						<div className='coder-gen'>Kibraken</div>
						{/* <div className='site-gen'>
							<Icon name='copyright outline' />
							<a href='https://whatareyou.shadesofvengeance.com/'>
								Shades of Vengeance
							</a>
						</div> */}
					</div>
				</React.Fragment>
			);
		};
		return (
			<React.Fragment>
				<MetaTags>
					<title>Are you Eulutian or Ximian?</title>
					<meta name='description' content='Are you Eulutian or Ximian?' />
					<meta property='og:title' content='Are you Eulutian or Ximian?' />
					<meta
						property='og:image'
						content='https://whatareyou.shadesofvengeance.com/img/consor/Ximian_Worker.png'
					/>
				</MetaTags>
				<div className='App'>
					<h2 className='b'>Are you Eulutian or Ximian? </h2>
					<br ref={this.myRef} />

					<div className='comic comicEmp'>
						{this.state.generated && (
							<Transition
								transitionOnMount={true}
								animation='scale'
								duration={1000}
								unmountOnHide={true}
								visible={this.state.comic}
							>
								<div className='test-panel panel'>
									<Image
										as='div'
										centered
										verticalAlign='middle'
										wrapped
										size='medium'
										src={this.state.imgPath}
										className='empImg'
									/>

									<div className='test-text'>
										<p style={{ lineHeight: "1.6em" }}>
											{this.state.name + ", your Ximian Name is "}
											<span className='con_gen'>
												{this.state.generatedName}
											</span>
											{`${
												this.state.cast == "Euluthian"
													? " You are an " + this.state.cast + "."
													: " You are a " + this.state.cast + " Caste Ximian."
											}`}
										</p>
									</div>
								</div>
							</Transition>
						)}
					</div>
					<Input
						action={{
							color: "blue",
							content: "OK",
							onClick: this.state.generated ? this.generated : this.generate
						}}
						actionPosition='left'
						placeholder={
							this.state.generated
								? "Generate Another Name"
								: "Enter your full name to find out!"
						}
						className='inp'
						value={this.state.value}
						onChange={e => this.changeValue(e.target.value)}
					/>

					{/* POSTGEN */}
					{this.state.generated && postgen()}
				</div>
			</React.Fragment>
		);
	}
}

export default Consortium;
